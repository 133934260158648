import React, {useState, useEffect, useRef} from 'react';

import {GatsbyImage, getImage} from "gatsby-plugin-image";

import {formatTime} from '../../utils/helpers';

const Video = ({video, videoPageTableData, videoPoster, videoUrl}) => {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [videoState, setVideoState] = useState('idle');

  const videoRef = useRef(null);
  const textRef = useRef(null);

  const handleVideoClick = () => {
    setIsVideoPlaying((prevIsVideoPlaying) => !prevIsVideoPlaying);
  };

  const handleVideoEnded = () => {
    setVideoState('idle');
    setIsVideoPlaying(false);
  }

  const handleOnlyfansClick = () => {
    const text = textRef.current.textContent;

    if (gtag) {
      gtag('event', 'video_play_start', {
        event_label: text,
        event_category: 'video',
      });
    }
  };

  useEffect(() => {
    if (isVideoPlaying) {
      const text = textRef.current.textContent;

      videoRef.current.play();
      setVideoState('playing');

      if (gtag) {
        gtag('event', 'video_play_start', {
          event_label: text,
          event_category: 'video',
        });
      }
    } else {
      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
        setVideoState('paused');
      }
    }
  }, [isVideoPlaying]);

  return (
    <>
      <div className="video-list--content video-wrapper">
        {(!isVideoPlaying && (videoState !== 'paused')) &&
          <GatsbyImage
            alt="Video Poster" image={getImage(videoPoster)} objectFit="cover"
            className="height-100"/>}
        <video ref={videoRef} playsInline={true} onEnded={handleVideoEnded}>
          <source src={videoUrl} type="video/mp4"/>
          Sorry, your browser doesn't support embedded videos.
        </video>

        <span className="price">${video.data.Price}</span>
        <span className="time"> {formatTime(video.data.Duration)}</span>

        <button className="btn-play" type="button" onClick={handleVideoClick}>
          {(videoState === 'idle' || videoState === 'paused') && (
            <img src="/img/btn-play.svg"/>
          )}
          {videoState === 'playing' && <img src="/img/btn-pause.svg"/>}
        </button>
      </div>
      <div className="video-list--content video-title">
        <h4>{video.data.Video_Title}</h4>
        <p>{video.data.Video_Description}</p>
      </div>
      <div className="video-list--content video-cta">
        <strong ref={textRef}>DM me: Video #{video.data.DM_Video_Code}</strong>
        <a
          href={videoPageTableData.Purchase_Link}
          onClick={handleOnlyfansClick}
        >
          Buy On: <img src="/img/onlyfans.svg"/>
        </a>
      </div>
    </>
  );
};

export default Video;
