import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import Helmet from 'react-helmet';

import Video from '../components/Video/Video';
import SEO from '../components/SEO/SEO';

import './dsg.css';

export default function DSG(props) {
  const { pageContext } = props;
  const { videoPageTableData, videosData } = pageContext;
  const categories = videoPageTableData.Categories;

  const [filteredVideos, setFilteredVideos] = useState([]);
  const [pageMounted, setPageMounted] = useState();
  const [localCategories, setLocalCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');
  const [videosChunks, setVideosChunks] = useState([]);

  const handleFilter = (event) => {
    event.preventDefault();

    const targetCategory = event.target.getAttribute('data-category').trim();
    setActiveCategory(targetCategory);

    let tmp = videosData.allAirtableVideos.nodes.filter((video) => {
      const videoTags = video.data['Name__from_Tags_'];

      if (!videoTags) {
        return false;
      }

      return videoTags.includes(targetCategory);
    });

    if (targetCategory === 'latest releases') {
      tmp = tmp.reverse();
    }

    setFilteredVideos(tmp);
  };

  useEffect(() => {
    if (!!filteredVideos.length) {
      setVideosChunks(_.chunk(filteredVideos, 4));
    }
  }, [filteredVideos]);

  useEffect(() => {
    setPageMounted(true);
  }, []);

  useEffect(() => {
    if (pageMounted) {
      let tmpArr = [];

      categories.forEach((singleCategory) => {
        const tmpCat = singleCategory.split(',');
        tmpArr.push(...tmpCat.map(el => el.trimStart().trim()));
      });

      const noDuplicatesTmpArr = [...new Set(tmpArr)];

      setLocalCategories(noDuplicatesTmpArr);

      const tmpVideos = videosData.allAirtableVideos.nodes.filter((video) => {
        const videoTags = video.data['Name__from_Tags_'];

        if (!videoTags) {
          return false;
        }

        return videoTags.includes(noDuplicatesTmpArr[0]);
      });

      setFilteredVideos(tmpVideos);
    }
  }, [pageMounted, categories]);

  useEffect(() => {
    if (!!localCategories.length) {
      setActiveCategory(localCategories[0].trim());
    }
  }, [localCategories]);

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: 'frame videos',
        }}
      />
      <SEO videoPageTableData={videoPageTableData} />
      <div id="outer-wrapper-videos" className="translate-z-in">
        <div id="inner-wrapper-videos">
          <div className="video-list--wrapper center">
            <div className="container">
              <div className="row row-videos-header">
                <div className="col-xs-12">
                  <header className="center">
                    <a
                      href="/"
                      id="brand"
                      className="fade-in animation-time-3s"
                    >
                      <h1>
                        <span className="pink-color">
                          {videoPageTableData.Header_Title_Part_One}
                        </span>
                        <span className="header-slim">
                          {videoPageTableData.Header_Title_Part_Two}
                        </span>
                      </h1>
                    </a>
                    <h3 className="video-list--header-title">
                      {videoPageTableData.H3_Header}
                    </h3>
                    <p className="video-list--header-caption">
                      {videoPageTableData.Subheader_Text}
                    </p>
                  </header>
                  <nav>
                    <ul className="btn-sort--wrapper">
                      {localCategories.map((category) => {
                        return (
                          <li
                            className={`btn-sort--single ${
                              activeCategory &&
                              category.trim() === activeCategory.trim()
                                ? 'btn-sort__active'
                                : ''
                            }`}
                            key={category}
                          >
                            <a
                              href="#"
                              onClick={handleFilter}
                              data-category={category}
                            >
                              {category.trim()}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="row row-videos-list">
                <div id="content-wrapper">
                  <div
                    id="content"
                    className="fade-in animation-time-05s row clearfix"
                  >
                    <ul className="clearfix col-xs-12">
                      {!!videosChunks.length &&
                        videosChunks.map((chunk, index) => {
                          return (
                            <div
                              className="row row-with-videos normal--sort "
                              key={`chunk-${activeCategory}-${index}`}
                            >
                              {chunk.map((video) => {
                                return (
                                  <li
                                    className="col-md-3 video-single-clip"
                                    key={video.data.UID}
                                  >
                                    <Video
                                      video={video}
                                      videoPageTableData={videoPageTableData}
                                      videoPoster={
                                        video.data.Thumbnail?.localFiles[0].childImageSharp
                                      }
                                      videoUrl={
                                        video.data.Video_Preview?.localFiles[0].publicURL
                                      }
                                    />
                                  </li>
                                );
                              })}
                            </div>
                          );
                        })}
                    </ul>
                  </div>
                </div>
              </div>

              <div className="row row-videos-footer">
                <footer>
                  <div className="social-icons pink-color">
                    <a
                      href="https://twitter.com/autumnfallsxoxo"
                      className="fade-in animation-time-1s delay-07s"
                    >
                      <i className="fab fa-twitter"></i>
                    </a>

                    <a
                      href="https://www.youtube.com/channel/UCTbKiiJzBg4KdnZQu4NP4Bg"
                      target="_blank"
                      className="fade-in animation-time-1s delay-06s"
                    >
                      <i className="fab fa-youtube"></i>
                    </a>
                    <a
                      href="https://www.twitch.tv/autumnfallsx3"
                      target="_blank"
                      className="fade-in animation-time-1s delay-05s"
                    >
                      <i className="fab fa-twitch"></i>
                    </a>
                    <a
                      href="https://www.instagram.com/autumnfallsx/"
                      target="_blank"
                      className="fade-in animation-time-1s delay-06s"
                    >
                      <i className="fab fa-instagram"></i>
                    </a>

                    <a
                      href="https://www.tiktok.com/@autumnfallsx"
                      target="_blank"
                      className="fade-in animation-time-1s delay-07s"
                    >
                      <i className="fab fa-tiktok"></i>
                    </a>
                  </div>
                </footer>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="backdrop"></div>
    </>
  );
}
